import "./styles.css";
import Maps from "../../images/maps.jpg";

function MapLocalization() {
   return (
      <div className="maps-site">
         <a
            className="maps-site-anchor"
            href="https://goo.gl/maps/b6dLNNyecWWPFLmG8"
            target="__blank"
         >
            <img src={Maps} alt="Localização do Prédio" />
         </a>
         <div className="maps-information">
            <h2 className="desktop-size maps-information-title txt-white txt-bigger">
               VIVA EM UM
               <br /> DOS PONTOS
               <br /> MAIS CENTRAIS
               <br /> DO RECIFE
            </h2>
            <h2 className="mobile-size maps-information-title txt-white txt-bigger txt-center">
               VIVA EM UM DOS PONTOS MAIS CENTRAIS DO RECIFE
            </h2>
            <div className="maps-information-subtitle txt-purple">
               <h3 className="txt-big">EXCELENTE LOCALIZAÇÃO</h3>
               <p className="txt-normal txt-bold">NAS PROXIMIDADES DO DERBY</p>
            </div>
            <p className="txt-white txt-small txt-bold">PRÓXIMO DE:</p>
            <div className="maps-information-reference txt-small txt-bold">
               <ul className="txt-purple ">
                  <li>SUPERMERCADOS</li>
                  <li>HOSPITAIS</li>
                  <li>FARMÁCIAS</li>
                  <li>ESCOLAS</li>
                  <li>PADARIAS</li>
               </ul>
            </div>
            <p className="txt-white txt-small txt-bold txt-center">
               CONFIRA A LOCALIZÇÃO CLICANDO NO MAPA
            </p>
         </div>
      </div>
   );
}

export default MapLocalization;
