import { useEffect } from "react";
import { useState } from "react";
import "./styles.css";

export default function FloatingPanel() {
   const [isVisible, setIsVisible] = useState(false);

   useEffect(() => {
      window.addEventListener("scroll", listenToScroll);
      return () => window.removeEventListener("scroll", listenToScroll);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const listenToScroll = () => {
      const winScroll =
         document.body.scrollTop || document.documentElement.scrollTop;

      const limit = document.querySelector(".header-site").clientHeight;

      if (winScroll >= limit) {
         setIsVisible(true);
      } else {
         setIsVisible(false);
      }
   };
   return (
      <>
         {isVisible && (
            <div className="floating-panel">
               <a href="#form" className="panel-anchor">
                  <p className="txt-small txt-orange">
                     <strong>CLIQUE AQUI</strong> E CADASTRE-SE PARA ACOMPANHAR
                     TODAS AS NOVIDADES DESTE LANÇAMENTO.
                  </p>
               </a>
            </div>
         )}
      </>
   );
}
