import "./styles.css";
import LogoMaxDerby from "../../images/LogoMaxDerby.png";
import LogoMaxPlural from "../../images/LogoMaxPlural.png";
//import PhoneNumberIcon from "../../images/PhoneNumberIcon.png";

import Form from "../Form";

function Footer() {
   return (
      <div className="footer-site">
         <div className="footer-info">
            <div className="footer-logos">
               <img
                  className="footer-max-derby-logo"
                  src={LogoMaxDerby}
                  alt="Logo da Max Derby"
               />
               <img src={LogoMaxPlural} alt="Logo da Max Plural" />
            </div>
            <div className="footer-contact txt-purple">
               {/* <div className="footer-contact-number txt-bold txt-big">
                  <img src={PhoneNumberIcon} alt="Phone Number icon" />
                  <a href="tel:40001581">
                     <span>4000-1581</span>
                  </a>
               </div> */}
               {/* <a
                  href="https://www.maxplural.com.br"
                  target="_blank"
                  rel="noreferrer"
                  className="txt-purple txt-normal txt-bold"
               >
                  www.maxplural.com.br
               </a> */}
            </div>
         </div>

         <div className="footer-form">
            <Form />
         </div>
      </div>
   );
}

export default Footer;
