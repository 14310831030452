import "./styles.css";
import Apt1 from "../../images/apt_showcase1.png";
import Apt2 from "../../images/apt_showcase2.png";
import Apt3 from "../../images/apt_showcase3.png";
import ApartmentDetail from "../../images/apartment_detail.png";
import Carousel from "../Carousel";

function ApartmentsDetails() {
   return (
      <div className="apartment-container txt-orange">
         <h1 className="apartment-title txt-bigger txt-center txt-bold">
            O SEU PONTO DE CONFORTO
         </h1>
         <div className="apartment-main-info">
            <div className="apartment-info">
               <img className="apartment-detail" src={ApartmentDetail} alt="" />
               <span className="apartment-subtitle txt-purple txt-bold txt-big">
                  More em um empreendimento
                  <br /> com a qualidade Max Plural,
                  <br /> com duas opções de planta e <br />
                  todo o conforto que você merece.
               </span>
               <div className="apartment-details">
                  <div className="apartment-minor-list txt-bold ">
                     <span className="apartment-minor-item txt-huge ">
                        1<span className="txt-bigger">&</span>2
                     </span>
                     <span className="apartment-minor-item txt-huge ">QTS</span>
                     <span className="apartment-minor-item txt-huge txt-normal">
                        (C/SUÍTE)
                     </span>
                  </div>
                  <ul className="apartment-details-list txt-bold txt-big">
                     <li className="apartment-details-item">COZINHA</li>
                     <li className="apartment-details-item">ÁREA DE SERVIÇO</li>
                     <li className="apartment-details-item">WC SOCIAL</li>
                  </ul>
               </div>
            </div>
            <div className="apartment-gallery">
               <Carousel screenAspect={"singleImg"} show={1}>
                  <div>
                     <img
                        className="apartment-img"
                        src={Apt1}
                        alt="Imagem do apartamento"
                     />
                  </div>
                  <div>
                     <img
                        className="apartment-img"
                        src={Apt2}
                        alt="Imagem do apartamento"
                     />
                  </div>
                  <div>
                     <img
                        className="apartment-img"
                        src={Apt3}
                        alt="Imagem do apartamento"
                     />
                  </div>
               </Carousel>
            </div>
         </div>
      </div>
   );
}

export default ApartmentsDetails;
