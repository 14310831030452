import LogoSite from "../../images/logo_site.png";
import Building from "../../images/building_sample.png";
import HeaderDetail from "../../images/header_detail.png";
import Form from "../Form";

import "./styles.css";

function Header() {
   return (
      <div id="form" className="header-site">
         <img className="header-detail" src={HeaderDetail} alt="" />
         <div className="header-content">
            <img className="header-logo" src={LogoSite} alt="logo do site" />
            <div className="header-informations">
               <img
                  className="header-building"
                  src={Building}
                  alt="logo do site"
               />
               <div className="header-merchan-section">
                  <h1 className="header-merchan-title txt-gold uppercase txt-bigger">
                     O Seu Melhor Ponto de Partida
                  </h1>
                  <p className="header-merchan-paragraph txt-white uppercase txt-small">
                     Venha morar em uma das localizações mais centrais da cidade
                     e viva com todas as facilidades do dia a dia pertinho de
                     você! Conheça o Max Derby Central e tenha o seu melhor
                     ponto de partida para a mobilidade, lazer, conforto e
                     qualidade de vida.
                  </p>
                  <p className="header-merchan-paragraph txt-orange uppercase txt-bold txt-small">
                     Cadastre-se para acompanhar todas as novidades deste
                     lançamento.
                  </p>
               </div>
               <Form />
            </div>
         </div>
      </div>
   );
}

export default Header;
