import "./styles.css";
import LeftDetail from "../../images/LeftDetail.png";
import RightDetail from "../../images/RightDetail.png";

import image1 from "../../images/ACADEMIA.png";
import image2 from "../../images/BIKE STATION.png";
import image3 from "../../images/COWORKING.png";
import image4 from "../../images/DRIVE LOUNGE.png";
import image5 from "../../images/LIVING GOURMET.png";
import image6 from "../../images/PET CARE2.png";
import image7 from "../../images/PISCINA_01.png";
import image8 from "../../images/PISCINA_02.png";
import image9 from "../../images/PISCINA_03.png";
import image10 from "../../images/PISCINA_04.png";
import image11 from "../../images/LAVANDERIA2.png";

import Carousel from "../Carousel";

function BannerCarousel() {
   return (
      <div className="banner-container">
         <div>
            <img
               className="banner-detail detail-left"
               src={LeftDetail}
               alt="#"
            />
            <div className="banner-inner-texts">
               <h1 className="banner-title txt-center txt-orange txt-bigger">
                  O SEU PONTO DE LAZER
               </h1>
               <p className="banner-subtitle txt-center txt-purple txt-big txt-bold">
                  Morar no Max Derby Central é ter uma estrutura
                  <br /> de lazer completa para você! Aproveite os melhores
                  <br />
                  momentos no conforto da sua própria casa.
               </p>
            </div>
            <img
               className="banner-detail detail-right"
               src={RightDetail}
               alt="#"
            />
         </div>
         {/* <div className="banner-carousel"> */}
         <Carousel screenAspect={"desktop"} show={3}>
            {/* <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={PoolImage}
                  alt="Imagem da Piscina"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={LoungeImage}
                  alt="Imagem do Lounge"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={RoomImage}
                  alt="Imagem da Sala de Jantar"
               />
            </div> */}
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image9}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image1}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image2}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image3}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image4}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image5}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image6}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image7}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image8}
                  alt="Imagem da Sala de Jantar"
               />
            </div>

            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image10}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image11}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
         </Carousel>
         <Carousel screenAspect={"mobile"} show={1}>
            {/* <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={PoolImage}
                  alt="Imagem da Piscina"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={LoungeImage}
                  alt="Imagem do Lounge"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={RoomImage}
                  alt="Imagem da Sala de Jantar"
               />
            </div> */}
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image9}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image1}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image2}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image3}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image4}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image5}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image6}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image7}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image8}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image10}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
            <div className="banner-carousel-box">
               <img
                  className="banner-carousel-img"
                  src={image11}
                  alt="Imagem da Sala de Jantar"
               />
            </div>
         </Carousel>
      </div>
      // </div>
   );
}

export default BannerCarousel;
