import "./styles.css";
import Header from "../../components/Header";
import MapLocalization from "../../components/MapLocalization";
import ApartmentsDetails from "../../components/ApartmentsDetails";
import BannerCarousel from "../../components/BannerCarousel";
import Footer from "../../components/Footer";
import FloatingPanel from "../../components/FloatingPanel";

function Home() {
   return (
      <div className="Homepage">
         <FloatingPanel />
         <Header />
         <MapLocalization />
         <ApartmentsDetails />
         <BannerCarousel />
         <Footer />
      </div>
   );
}

export default Home;
